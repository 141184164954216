<template>
  <div class="dialog-picker" v-if="visable">
    <div class="left">
      <h5>Absolute time range</h5>
      <el-date-picker
        v-model="currentDate"
        type="daterange"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        align="left"
        :editable="false"
        :picker-options="pickerOptions">
      </el-date-picker>
    </div>
    <div class="right">
      <h5>Search quick ranges</h5>
      <el-select v-model="quickValue" placeholder="Search quick ranges" @change="quickChange">
        <el-option
          v-for="item in quickList"
          :key="item.value"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="bottom">
      <h5>Change time settings</h5>
      <el-select v-model="utcValue" placeholder="Change time settings" @change="utcChange">
        <el-option
          v-for="item in utcList"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <el-button slot="append" type="primary" class="apply" @click="applyClick">Apply</el-button>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: ['visable'],
  data () {
    return {
      today: '',
      currentDate: [],
      pickerOptions: {
        onPick: ({minDate, maxDate}) => {
          if (minDate && maxDate) {
            this.minDate = moment(minDate).format('YYYY-MM-DD');
            this.maxDate = moment(maxDate).format('YYYY-MM-DD');
            this.quickValue = '';
            this.quickTime = '';
            console.log(this.minDate, this.maxDate);
          }
        }
      },
      quickList: [
        {
          value: 'Today'
        }, {
          value: 'Last 15 minutes'
        }, {
          value: 'Last 30 minutes'
        }, {
          value: 'Last 1 hour'
        }, {
          value: 'Last 2 days'
        },  {
          value: 'Last 7 days'
        }, {
          value: 'Last 30 days'
        }, {
          value: 'Last 90 days'
        }
      ],
      quickValue: '',
      utcList: [{
        value: '1',
        label: '用户时区'
      }, {
        value: '2',
        label: 'Asia/Shanghai'
      }, {
        value: '3',
        label: 'America/Chicago'
      }, {
        value: '4',
        label: 'America/Denver'
      }, {
        value: '5',
        label: 'America/Detroit'
      }, {
        value: '6',
        label: 'America/Phoenix'
      }, {
        value: '7',
        label: 'America/New_York'
      }, {
        value: '8',
        label: 'America/Los_Angeles'
      }],
      utcValue: ''
    };
  },
  methods: {
    quickChange (val) {
      let quickTime;
      let date = new Date();
      switch (val) {
      case 'Today':
        quickTime = new Date(date.toLocaleDateString()).getTime();
        break;
      case 'Last 15 minutes':
        quickTime = date.setTime(date.getTime() - 900 * 1000);
        break;
      case 'Last 30 minutes':
        quickTime = date.setTime(date.getTime() - 1800 * 1000);
        break;
      case 'Last 1 hour':
        quickTime = date.setTime(date.getTime() - 3600 * 1000);
        break;
      case 'Last 2 days':
        quickTime = date.setTime(date.getTime() - 3600 * 1000 * 24 * 2);
        break;
      case 'Last 7 days':
        quickTime = date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
        break;
      case 'Last 30 days':
        quickTime = date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
        break;
      case 'Last 90 days':
        quickTime = date.setTime(date.getTime() - 3600 * 1000 * 24 * 90);
        break;
      default:
        break;
      }
      this.quickTime = quickTime;
      console.log(this.quickValue, quickTime);
      this.currentDate = '';
      this.minDate = '';
      this.maxDate = '';
    },
    utcChange (id) {
      console.log(id);
      this.utcValue = id;
    },

    applyClick () {
      let that = this;
      let data = {params: {}};
      if (that.currentDate.length) {
        let sameDate = that.minDate == that.maxDate;
        data.text = sameDate ? that.maxDate : `${that.minDate} → ${that.maxDate}`;
        data.params = {
          start_time: new Date(moment(that.minDate).format('YYYY/MM/DD')).getTime(),
          end_time: sameDate ? new Date().getTime() : new Date(moment(that.maxDate).format('YYYY/MM/DD')).getTime()
        };
      }
      if (that.quickValue) {
        data.text = that.quickValue;
        data.params = {
          start_time: that.quickTime,
          end_time: new Date().getTime()
        };
      }
      if (that.utcValue) {data.params.timezone = that.utcValue;}
      console.log(data);
      that.$emit('applydate', data);
      that.$emit('update:visable', false);
    }
  },
  created () {

  }
};
</script>

<style>
.dialog-picker{
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 999;
  min-width: 112px;
  max-width: calc(100vw - 32px);
  padding: 16px;
  box-shadow: 0 12px 24px 0 rgb(65 78 101 / 10%), 0 6px 12px 0 rgb(65 78 101 / 10%), 0 4px 4px 0 rgb(65 78 101 / 10%), 0 2px 2px 0 rgb(65 78 101 / 10%);
  transform: translateY(-8px) translateZ(0);
  background: #FFF;
  border: 1px solid #D3DAE6;
  border-color: #d0d3d9;
  border-top-color: #d9dce0;
  border-bottom-color: #aaafba;
}
h5{
  margin: 10px 0;
}
.apply{
  float: right;
}
</style>