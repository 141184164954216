<template>
    <el-popover
    v-model="visible"
    @show="showModal">
        <!-- filter add -->
        <el-button v-if="openType == 'add'" slot="reference" type="text" class="btn-filter">+Add filter</el-button>
        <!-- filter text -->
        <div v-else slot="reference" class="text btn-filter-item">
            <el-button type="text" >{{operationText}}</el-button>
            <el-button @click.stop="handleDel" slot="reference" icon="el-icon-circle-close" circle size="mini"></el-button>
        </div>

        <!-- filter dialog -->
        <div class="left">
            <div>Field</div>
            <el-select v-model="key" placeholder="Select a field first" @change="keyChange">
                <el-option
                v-for="(item, index) in keyList"
                :key="index"
                :value="item.value"
                :label="item.label">
                </el-option>
            </el-select>

            <h5>Operator</h5>
            <el-select v-model="operator" placeholder="Select" @change="operatorChange">
                <el-option
                v-for="(item, index) in operatorList"
                :key="index"
                :value="item.value"
                :label="item.label">
                </el-option>
            </el-select>

            <h5>Value</h5>
            <el-popover
                v-if="showPopover"
                placement="top-start"
                title="role_id:"
                width="200"
                trigger="hover"
                content="Mom: 1, Dad: 2, Son/Daughter/Child: 3, Grandparent: 4, Partner/Spouse: 5, Friend: 6, Other: 7">
                <el-button class="btn-popover" slot="reference" icon="el-icon-view" circle size="mini"></el-button>
            </el-popover>
            <div v-if="valueSelect">
                <el-select v-model="value" filterable placeholder="Select a value" @change="valueChange">
                <el-option
                    v-for="(item, index) in valueList"
                    :key="index"
                    :value="item.value"
                    :label="item.label">
                </el-option>
                </el-select>
            </div>
            <div v-if="!valueSelect">
                <el-input v-model="value" placeholder="Enter a value"></el-input>
            </div>
            <div v-if="valueNumber">
                <h5>Number</h5>
                <el-input v-model="number" placeholder="Number"></el-input>
            </div>

            <el-button slot="append" type="primary" class="apply" @click="handleApply">Apply</el-button>
        </div>
    </el-popover>
</template>

<script>
export default {
  props: {
    itemData: Object,
    openType: String,
    itemIndex: Number,
    keyList: Array
  },
  data () {
    return {
      visible: false,
      operatorList: [],
      valueList: [],
      key: '',
      keyText: '',
      operator: '',
      value: '',

      operationText: '',
      showPopover: false,
      valueSelect: false,
      valueNumber: false,
      number: '',
      // notifyType: ['', 'mins', 'hours', 'days']
      notifyType: ['No', 'n minutes before', 'n hours before', 'n days before']
    };
  },
  created () {
    // 初始化条件，展开弹窗时内容显示的不同
    // this.operationText = `${this.itemData.keyText}  ${this.itemData.operator}  ${this.itemData.value}`;
    let text = this.itemData.value;
    if (this.itemData.keyText == 'Reminder1' || this.itemData.keyText == 'Reminder2') {
      if (this.itemData.value != 'No' && this.itemData.value != 'At the time of event') {
        let str = this.itemData.value.split(' ');
        text = `${this.itemData.number} ${str[1]} ${str[2]}`;
      }
    }
    this.operationText = `${this.itemData.keyText}  ${this.itemData.operator}  ${text}`;
  },
  methods: {
    // openModal (data) {
    //   console.log('openModal', data);
    //   this.key = data.keys;
    //   this.operator = data.operator;
    //   this.value = data.values;
    // },
    showModal () {
      console.log('showModal', this.itemData);
      let isAddFilter = this.openType == 'add';
      this.key = isAddFilter ? '' : this.itemData.key;
      this.keyText = isAddFilter ? '' : this.itemData.keyText;
      this.operator = isAddFilter ? '' : this.itemData.operator;
      this.value = isAddFilter ? '' : this.itemData.value;
      let text = this.value;
      if (this.keyText == 'Reminder1' || this.keyText == 'Reminder2') {
        if (this.value != 'No' && this.value != 'At the time of event') {
          let str = this.value.split(' ');
          text = `${this.number} ${str[1]} ${str[2]}`;
        }
      }
      this.operationText = `${this.keyText}  ${this.operator}  ${text}`;
      this.checkList(this.keyText);
    },
    // 删除
    handleDel () {
      this.$emit('delete', this.itemIndex);
    },

    // 筛选条件
    keyChange (val) {
      this.valueSelect = false;
      this.valueNumber = false;
      let opt = opt = this.keyList.find((item) => item.value === val);
      this.checkList(opt.label);
      this.operator = '';
      this.value = '';
      this.number = '';
      this.keyText = opt.label;
    },
    operatorChange (val) {
      switch (val) {
      case 'is one of':
        this.valueSelect = false;
        this.showPopover = false;
        if (this.keyText == 'Role') {
          this.showPopover = true;
        }
        break;
      default:
        break;
      }
    },
    valueChange (val) {
      if (val == 'n minutes before' || val == 'n hours before' || val == 'n days before') {
        this.valueNumber = true;
      }
    },

    // 回传参数给父页面
    handleApply () {
      console.log(`${this.key} ${this.operator} ${this.value}`);
      if (this.keyText == 'Reminder1' || this.keyText == 'Reminder2') {
        // if (item.notify_before[i] == 0) {
        //   str = item.notify_type[i] == 0 ? 'No' : 'At the time of event';
        // } else {
        //   let after = this.notifyType[item.notify_type[i]];
        //   str = `${item.notify_before[i]} ${after} before`;
        // }
        let text = this.value;
        if (this.operator == '=' && this.value != 'No' && this.value != 'At the time of event') {
          let str = this.value.split(' ');
          text = `${this.number} ${str[1]} ${str[2]}`;
        }
        this.operationText = `${this.keyText}  ${this.operator}  ${text}`;
        console.log('---------', this.operationText);
        this.$emit('apply', {
          key: this.key,
          operator: this.operator,
          value: this.value,
          keyText: this.keyText,
          number: this.number,
          isAddFilterKey: this.openType == 'add'
        });
        // let typeIndex = this.notifyType.indexOf(this.value);
        // if (isOne) {
        //   notify_type[0] = typeIndex > -1 ? typeIndex : 0;
        //   notify_before[0] = this.number || 0;
        // } else {
        //   notify_type[1] = typeIndex > -1 ? typeIndex : 0;
        //   notify_before[1] = this.number || 0;
        // }
        // console.log(notify_type);
        // console.log(notify_before);
        // this.$emit('apply', {
        //   key: 'notify_type',
        //   operator: this.operator,
        //   value: notify_type
        // }, {
        //   key: 'notify_before',
        //   operator: this.operator,
        //   value: notify_before
        // });
      } else {
        this.operationText = `${this.keyText}  ${this.operator}  ${this.value}`;
        console.log('-----',this.operationText)
        this.$emit('apply', {
          key: this.key,
          operator: this.operator,
          value: this.value,
          keyText: this.keyText,
          isAddFilterKey: this.openType == 'add'
        });
      }
      this.visible = false;
    },

    checkList (key) {
      console.log('checkList', key)
      let list = [], value = [];
      switch (key) {
      case 'Role':
        list = [{value: '='}, {value: '≠'}, {value: 'is one of'}];
        value = [{value: 'Mom'}, {value: 'Dad'}, {value: 'Son/Daughter/Child'}, {value: 'Grandparent'}, {value: 'Partner'}, {value: 'Friend'}, {value: 'Other'}];
        this.valueSelect = true;
        break;
      case 'User_id':
      case 'Event_id':
      case 'Event_Title':
      case 'Note':
      case 'Subscriber id':
      case 'Create_Member':
      case 'Participates':
      case 'Visible by':
      case 'App_Version':
        list = [{value: '='}, {value: '≠'}, {value: 'is one of'}];
        break;
      case 'Location':
      case 'Family_id':
        list = [{value: '='}, {value: '≠'}, {value: 'is one of'}, {value: 'exists'}];
        break;
      case 'Age':
      case 'notify_type':
      case 'notify_before':
        list = [{value: '='}, {value: '≠'}, {value: '>'}, {value: '<'}, {value: 'is one of'}, {value: 'exists'}];
        break;
      case 'Fam_Size':
      case 'Fam_Event_Num':
      case 'Event_Start':
      case 'Event_End':
        list = [{value: '='}, {value: '≠'}, {value: '>'}, {value: '<'}, {value: 'is one of'}];
        break;
      case 'Reminder1':
      case 'Reminder2':
        list = [{value: '='}, {value: 'exists'}];
        value = [{value: 'No'}, {value: 'At the time of event'}, {value: 'n minutes before'}, {value: 'n hours before'}, {value: 'n days before'}];
        this.valueSelect = true;
        break;
      case 'VIP':
        list = [{value: '='}];
        value = [{value: 'Yes'}, {value: 'No'}];
        this.valueSelect = true;
        break;
      case 'Plan':
        list = [{value: '='}];
        value = [{value: 'Monthly'}, {value: 'Yearly'}, {value: 'Free'}];
        this.valueSelect = true;
        break;
      case 'Recurring':
        list = [{value: '='}];
        value = [{value: 'No'}, {value: 'Every Day'}, {value: 'Every Week'}, {value: 'Every Month'}, {value: 'Every Year'}, {value: 'Every other Day'}, {value: 'Every other Week'}, {value: 'Every other Month'}, {value: 'Every other Year'}];
        this.valueSelect = true;
        break;
      case 'Status':
        list = [{value: '='}, {value: '≠'}];
        value = [{value: 'Deleted'}, {value: '--'}];
        this.valueSelect = true;
        break;
      case 'From':
        list = [{value: '='}, {value: 'is one of'}];
        value = [{value: 'top'}, {value: 'view'}, {value: 'widget'}, {value: 'color'}, {value: 'reminder'}, {value: 'search'}, {value: 'visible'}, {value: 'theme_color'}, {value: 'default'}];
        this.valueSelect = true;
        break;
      case 'From_user':
        list = [{value: '='}, {value: '≠'}, {value: 'is one of'}];
        value = [{value: 'widget'}, {value: 'home'}];
        this.valueSelect = true;
        break;
      case 'System':
        list = [{value: '='}, {value: '≠'}, {value: 'is one of'}];
        value = [{value: 'iOS'}, {value: 'Android'}];
        this.valueSelect = true;
        break;
      default:
        break;
      }
      this.operatorList = list;
      this.valueList = value;
    }
  }
};
</script>
<style lang="scss">
.btn-filter-item {
  display: inline-block;
  border: 1px solid #DCDFE6;
  margin: 0 10px;
  .el-button--text{
    color: #606266;
    padding: 0;
  }
}
.btn-popover{
  position: absolute;
  top: 0;
  right: 0;
}
.apply{
  float: right;
  margin-top: 16px;
}
</style>